<template>
<div class="space-y-4">
	<div class="text-center">
    <vs-avatar v-if="!profile.avatar_thumbnail" size="70px" class="text-6xl avatar-img" :color="getColor(profile.name)" :text="getInitial(profile.name)" />
		<vs-avatar v-if="profile.avatar_thumbnail" size="70px" class="text-6xl avatar-img" :src="profile.avatar_thumbnail" />
	</div>
	<div>
		<div class="text-gray">{{ $t('Name') }}</div>
		<div>{{ user && user.name && user.name !== 'Anonymous' ? user.name : $t('Anonymous') }}</div>
	</div>
	<div>
		<div class="text-gray">{{ $t('Company') }}</div>
		<div>{{ user && user.company ? user.company : '-' }}</div>
	</div>
	<div>
		<div class="text-gray">{{ $t('Email') }}</div>
		<div>{{ user && user.email ? user.email : '-' }}</div>
	</div>
	<div>
		<div class="text-gray">{{ $t('Language') }}</div>
		<div>{{ language }}</div>
	</div>
</div>
</template>

<script>
import {
  getFirstCharacter, generateColorByAlphabet,
} from '@/lib/helper';

export default {
  components: {
  },
  props: {
    profile: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      user: null,
    };
  },
  computed: {
    language() {
      const lang = this.user && this.user.language ? this.user.language : 'en';
      return lang === 'en' ? 'English' : 'Norsk';
    },
  },
  methods: {
    getInitial(str) {
      return getFirstCharacter(str);
    },
    getColor(str) {
      return generateColorByAlphabet(str);
    },
    onGetFirstWord(str) {
      return str && str.length > 0 ? str[0] : '';
    },
  },
  mounted() {

  },
  created() {
    this.user = this.profile;
  },
  destroyed() {
  },
};
</script>
