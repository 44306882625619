<template>
	<div
    class="the-navbar__user-meta flex items-center p-4 fixed top-0 right-0"
    v-if="userName">
		<div class="text-right leading-tight hidden sm:block">
			<p class="font-semibold truncate text-lg" style="max-width: 130px">{{ userName === 'Anonymous' ? $t(userName) : userName }}</p>
		</div>

		<vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer" ref="vsProfileDropdown">
			<div class="con-img ml-3">
				<vs-avatar v-if="userProfile && userProfile.avatar_thumbnail" class="user-profile-img main-nav-profile" :src="userProfile && userProfile.avatar_thumbnail ? userProfile.avatar_thumbnail : ''" size="40px" />
				<vs-avatar v-if="userProfile && !userProfile.avatar_thumbnail" :color="getColor(userName)" :text="getInitial(userName || A)" class="user-profile-img main-nav-profile" size="40px" />

			</div>

			<vs-dropdown-menu class="vx-navbar-dropdown profile-menu-dropdown">
				<!-- Admin menu -->
				<ul class="profile-list-item" v-if="!isLiveMenu">
					<li v-if="!isModeratorGuest" class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white" @click="$router.push('/profile').catch(() => {})">
						<feather-icon icon="UserIcon" svgClasses="w-4 h-4" />
						<span class="ml-2">{{ $t('Profile') }}</span>
					</li>

					<vs-divider v-if="!isModeratorGuest" class="m-1" />

					<li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white" @click="logout()">
						<feather-icon icon="LogOutIcon" svgClasses="w-4 h-4" />
						<span class="ml-2">{{$t('Logout')}}</span>
					</li>
				</ul>

				<!-- Live menu -->
				<template v-if="isLiveMenu">
					<vs-dropdown-item @click="showProfile()">
						<div class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white w-30">
							<feather-icon icon="UserIcon" svgClasses="w-4 h-4" />
							<span class="ml-2">{{ $t('My Profile') }}</span>
						</div>
					</vs-dropdown-item>

          <vs-dropdown-item v-if="isShowMenuSwitchQuestion" @click="switchEvent()">
						<div class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white w-30">
							<feather-icon icon="MessageSquareIcon" svgClasses="w-4 h-4" />
							<span class="ml-2">{{$t('Switch Event')}}</span>
						</div>
					</vs-dropdown-item>

					<vs-dropdown-item v-if="isShowMenuSwitchQuestion" @click="showMyQuestion()">
						<div class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white w-30">
							<feather-icon icon="MessageSquareIcon" svgClasses="w-4 h-4" />
							<span class="ml-2">{{$t('My Question')}}</span>
						</div>
					</vs-dropdown-item>

          <!-- <vs-dropdown-item @click="logout()">
						<div class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white w-30">
							<feather-icon icon="LogOutIcon" svgClasses="w-4 h-4" />
							<span class="ml-2">{{$t('Logout')}}</span>
						</div>
					</vs-dropdown-item> -->
				</template>
			</vs-dropdown-menu>
		</vs-dropdown>

		<!-- Modals -->
		<div class="modals">
			<!-- My Profile -->
			<modal-profile
        ref="modalProfile"
        :event="event"
				:show="isShowProfile"
				@close="closeProfile()"
				@showDeleteProfile="showDeleteProfile"
        :isExtendedEvent="isExtendedEvent"
			/>

			<!-- My Questions -->
			<modal-my-questions
        :event="event"
				:show="isShowMyQuestion"
				@close="closeMyQuestion()"
				@showWithdrawQuestion="showWithdrawQuestion"
				@showEditQuestion="showEditQuestion"
				@showWarningEdit="showWarningEdit"
        ref="modalMyQuestions"
			/>

      <!-- Modal - Add question -->
      <modal-add-question
        :show="isShowEditQuestion"
        :event="event"
        :selected="selectedQuestion"
        :eventSettings="eventSettings"
        @close="closeEditQuestion()"
      />
		</div>

		<!-- Delete Profile -->
		<vs-popup
			classContent="delete-profile"
			:title="$t('Delete Profile')"
			:active.sync="isShowDeleteProfile">
			{{ $t('You are about to delete your personal information. All your data will be anonymized and you will be logged out from this event. This action cannot be undone.') }}
			<vs-divider/>
			<vs-row vs-type="flex" vs-justify="flex-end">
				<vs-button color="#B8B8B8" class="mr-3" type="filled" @click="closeDeleteProfile()">{{$t('Cancel')}}</vs-button>
				<vs-button color="primary" type="filled" @click="confirmDeleteProfile()">{{$t('Confirm')}}</vs-button>
			</vs-row>
		</vs-popup>

		<!-- Modal - Withdraw -->
		<vs-popup
			classContent="withdraw-question"
			:title="$t('Withdraw Question')"
			:active.sync="isShowWithdrawQuestion">
			{{ $t("Your question will be withdrawn from the Q&A and there's no way back.") }}
			<vs-divider/>
			<vs-row vs-type="flex" vs-justify="flex-end">
				<vs-button :disabled="isDeleting" color="#B8B8B8" class="mr-3" type="filled" @click="closeWithdrawQuestion()">{{$t('Cancel')}}</vs-button>
				<vs-button :class="{ 'is-loading': isDeleting }" color="primary" type="filled" @click="approveWithdrawQuestion()">{{$t('Confirm')}}</vs-button>
			</vs-row>
		</vs-popup>

    <!-- Modal - Warning Edit Question -->
		<vs-popup
      classContent="edit-warning-question"
      :title="$t('Edit Question')"
      :active.sync="isShowWarningEdit">
      {{ $t("You can't edit your question anymore but you can withdraw and resend it.") }}
      <vs-divider/>
      <vs-row vs-type="flex" vs-justify="flex-end">
        <vs-button color="primary" type="filled" @click="closeWarningEdit()">{{$t('Got It')}}</vs-button>
      </vs-row>
    </vs-popup>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import questionApi from '@/api/question';
import participantApi from '@/api/participant';
import { duplicateVar, getFirstCharacter, generateColorByAlphabet } from '@/lib/helper';

import ModalProfile from '@/components/events/ModalProfile.vue';
import ModalMyQuestions from '@/components/events/questions/ModalMyQuestions.vue';
import ModalAddQuestion from '@/components/questions/ModalAddQuestion.vue';

export default {
  components: {
    ModalProfile,
    ModalMyQuestions,
    ModalAddQuestion,
  },
  data() {
    return {
      isShowProfile: false,
      isShowMyQuestion: false,
      isShowDeleteProfile: false,
      isShowWithdrawQuestion: false,
      selectedQuestion: null,
      isDeleting: false,
      isShowEditQuestion: false,
      isShowWarningEdit: false,
    };
  },
  props: {
    isLiveMenu: {
      type: Boolean,
      default: () => false,
    },
    event: {
      type: Object,
      default: null,
    },
    eventSettings: {
      type: Object,
      default: () => null,
    },
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'isLoggedIn',
      user: 'user',
    }),
    userProfile() {
      const profile = this.user ? this.user : null;
      return profile;
    },
    userName() {
      const userName = this.user && this.user.name ? `${this.user.name}` : 'Anonymous';
      return userName;
    },
    isModeratorGuest() {
      const moderator = localStorage.getItem('is_moderator');
      return moderator;
    },
    isExtendedEvent() {
      return this.eventSettings && this.eventSettings.sidebar && this.eventSettings.sidebar.is_enable_extended_field;
    },
    isShowMenuSwitchQuestion() {
      const isMenu = this.eventSettings && this.eventSettings.style ? this.eventSettings.style : false;
      if (!Object.hasOwnProperty.call(this.eventSettings.style, 'is_show_switch_question')) return true;
      return isMenu && isMenu.is_show_switch_question ? isMenu.is_show_switch_question : false;
    },
  },
  methods: {
    logout() {
      this.$router.push('/logout');
    },
    generateInitial() {
      const words = this.userProfile && this.userProfile.name ? this.userProfile.name.split(' ') : 'AN';
      const initial = `${words[0][0]}`;
      return initial;
    },
    showProfile() {
      if (this.isExtendedEvent) {
        this.$router.push(`/profile/${btoa(this.userProfile.id)}?event=${this.event.link_code_value}`);
      } else {
        this.isShowProfile = true;
      }
    },
    closeProfile() {
      this.isShowProfile = false;
    },
    showMyQuestion() {
      this.isShowMyQuestion = true;
    },
    closeMyQuestion() {
      this.isShowMyQuestion = false;
    },
    switchEvent() {
      this.$emit('switchEvent');
    },
    showDeleteProfile() {
      this.isShowDeleteProfile = true;
    },
    closeDeleteProfile() {
      this.isShowDeleteProfile = false;
    },
    confirmDeleteProfile() {
      this.isDeleting = true;
      localStorage.removeItem('userInfo');
      localStorage.removeItem('access_token');
      const callback = () => {
        this.$router.push('/logout');
      };

      const errCallback = (err) => {
        console.log(err);
      };

      participantApi.deleteProfile(callback, errCallback);
    },
    showWithdrawQuestion(question) {
      this.selectedQuestion = duplicateVar(question);
      this.isShowWithdrawQuestion = true;
    },
    closeWithdrawQuestion() {
      this.isShowWithdrawQuestion = false;
      this.selectedQuestion = null;
    },
    approveWithdrawQuestion() {
      if (!this.selectedQuestion) return;

      this.isDeleting = true;
      const callback = () => {
        this.isDeleting = false;
        this.$refs.modalMyQuestions.onDelete(this.selectedQuestion);
        this.closeWithdrawQuestion();
      };
      const errorCallback = () => {
        this.isDeleting = false;
        this.isModalActiveLocal = false;
      };
      questionApi.delete(this.selectedQuestion.id, callback, errorCallback);
    },
    showEditQuestion(question) {
      this.selectedQuestion = duplicateVar(question);
      this.isShowEditQuestion = true;
    },
    closeEditQuestion() {
      this.isShowEditQuestion = false;
      this.selectedQuestion = null;
    },
    showWarningEdit() {
      this.isShowWarningEdit = true;
    },
    closeWarningEdit() {
      this.isShowWarningEdit = false;
    },
    getInitial(str) {
      return getFirstCharacter(str);
    },
    getColor(str) {
      return generateColorByAlphabet(str);
    },
  },
};
</script>
<style lang="scss">
.main-nav-profile .vs-avatar--con-img img {
  width: 50px;
	height:50px;
	object-fit:cover;
}
.navbar-profile .vs-avatar--text {
	font-size: 1.8em;
}
.thumbnail-profile-dropdown-main {
	position: relative;
	min-height: 40px;
	max-height: 40px;
	min-width: 40px;
	max-width: 40px;
	text-align: center;
	.initial-profile-dropdown-main {
		font-size: 1.3em;
		padding: 6px;
		min-width: 40px;
		max-width: 40px;
		margin: 0 auto;
		border-radius: 50%;
		background-color: red;
		color: #fff;
	}
}
</style>
