<template>
	<modal
		class="modal-profie"
		size="small-medium"
		:hide-footer="true"
		:show-close="true"
    :isMobile="windowWidth < 700 ? true : false"
    :hasScroll="true"
		:isEnableKeyUp="false"
		@close="close"
		v-if="show">
		<div slot="header">
			<div class="flex items-center">
				<vs-icon
					class="answer-question-icon mr-1 cursor-pointer"
					color="#595959"
					size="medium"
					@click="clearViewSelected()"
					v-if="selected">
					chevron_left
				</vs-icon>
				<h5 class="modal-title">
					{{ $t('My Question') }}
					<span v-if="selected">- {{ $t("Details") }}</span>
				</h5>
			</div>
		</div>
		<div slot="body">
			<!-- Questions -->
			<div v-if="totalQuestions > 0 && !selected"
        :style="windowWidth < 700 ? 'max-height: 90vh; overflow-y: auto': 'max-height: 70vh; overflow-y: auto'"
      >
				<!-- List -->
				<div
					v-for="(question, index) in questions" :key="index"
					class="question-list"
					>
					<!-- Item -->
					<question-item
						:question="question"
						:is-show-first-reply="false"
						:is-show-total-reply="true"
						:is-show-action="true"
						@viewSelected="viewSelected"
						@showWithdrawQuestion="showWithdrawQuestion"
            @showEdit="showEditQuestion"
            @showWarningEdit="showWarningEdit"
					/>
				</div>
			</div>

			<div v-if="selected">
				<!-- Item -->
				<question-item
					:question="selected"
					:is-show-first-reply="false"
					:is-show-total-reply="false"
					:is-show-total-like="true"
					:is-show-action="false"
					:is-show-list-replies="true"
					:is-show-action-details="false"
					@showWithdrawQuestion="showWithdrawQuestion"
          @showEdit="showEditQuestion"
          @showWarningEdit="showWarningEdit"
				/>
			</div>

			<!-- If no questions -->
			<div class="question-list__empty" v-if="totalQuestions === 0 && !isFetching">
				{{ $t('No question yet, be the first to ask') }}
			</div>

			<!-- Fetching -->
			<div class="py-10 text-center" v-if="isFetching">
				<div class="is-loading large dark"></div>
			</div>
		</div>
	</modal>
</template>

<script>
import questionApi from '@/api/question';
import { mapGetters } from 'vuex';
import Modal from '@/components/Modal.vue';
import QuestionItem from '@/components/questions/QuestionItem.vue';

export default {
  components: {
    Modal,
    QuestionItem,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    event: {
      type: Object,
      default: null,
    },
    participant: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      accessCode: null,
      isSubmitting: false,
      questions: [],
      selected: null,
      page: 1,
      limit: 9999,
      orderBy: 'likes',
      sortBy: 'asc',
      isFetching: false,
      windowWidth: window.innerWidth,
    };
  },
  created() {
  },
  mounted() {
    this.checkScreenSize();
  },
  destroyed() {
  },
  sockets: {
    questions_update(question) {
      this.onUpdateQuestion(question);
    },
  },
  watch: {
    show() {
      if (this.show) this.fetchList(true);
    },
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'isLoggedIn',
      user: 'user',
    }),
    totalQuestions() {
      return this.questions.length;
    },
  },
  methods: {
    checkScreenSize() {
      window.onresize = () => {
        this.windowWidth = window.innerWidth;
      };
    },
    close() {
      this.$emit('close');
    },
    viewSelected(question) {
      this.selected = question;
    },
    clearViewSelected() {
      this.selected = null;
    },
    showWithdrawQuestion(question) {
      this.$emit('showWithdrawQuestion', question);
    },
    showEditQuestion(question) {
      this.$emit('showEditQuestion', question);
    },
    showWarningEdit(question) {
      this.$emit('showWarningEdit', question);
    },
    fetchList(isReset = false) {
      if (!this.event) return;
      if (isReset) {
        this.page = 1;
        this.questions = [];
      }
      const params = {
        page: this.page,
        limit: this.limit,
        order_by: this.orderBy,
        sort_by: this.sortBy,
        is_mine: true,
      };
      this.isFetching = true;
      const callback = (response) => {
        const data = response.data;
        this.questions = data;
        this.isFetching = false;
      };
      const errorCallback = () => {
        this.isFetching = false;
      };
      questionApi.list(this.event.id, params, callback, errorCallback);
    },
    onDelete(question) {
      // remove replies
      if (question && question.parent_id) {
        const index = this.questions.findIndex(({ id }) => id === question.parent_id);
        const indexReplies = this.questions[index].replies.findIndex(({ id }) => id === question.id);
        this.questions[index].replies.splice(indexReplies, 1);
      } else {
        const index = this.questions.findIndex(({ id }) => id === question.id);
        this.questions.splice(index, 1);
      }
    },
    onUpdateQuestion(question) {
      if (question && question.parent_id) {
        const index = this.questions.findIndex(({ id }) => id === question.parent_id);
        const indexReplies = this.questions[index].replies.findIndex(({ id }) => id === question.id);
        this.$set(this.questions[index].replies, indexReplies, question);
        if (indexReplies === -1) {
          this.questions[index].replies.push(question);
        }
      } else {
        const index = this.questions.findIndex(({ id }) => id === question.id);
        this.$set(this.questions, index, question);
      }
    },
  },
};
</script>
