<template>
<div class="space-y-4">
  <input type="file" class="hidden" ref="updateImgProfile" @change="selectImage" accept="image/*">
	<div v-if="isShowAvatar" class="text-center" @click="isExtendedEvent ? $refs.updateImgProfile.click() : null">
		<vs-avatar v-if="!profile.avatar_thumbnail" size="70px" class="text-6xl avatar-img" :color="getColor(profile.name)" :text="getInitial(profile.name)" />
		<vs-avatar v-if="profile.avatar_thumbnail" size="70px" class="text-6xl avatar-img" :src="profile.avatar_thumbnail" />
	</div>
  <div v-if="isExtendedEvent" class="modify-img flex mt-2 justify-center">
    <vs-button color="secondary" type="border" class="p-1 px-4 "
      @click="$refs.updateImgProfile.click()">
      <div class="flex items-center pt-1">
        <svg class="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24">
          <path d="M9,16V10H5L12,3L19,10H15V16H9M5,20V18H19V20H5Z" /></svg>
        {{$t('Upload Avatar')}}
      </div>
    </vs-button>
    <vs-button class="ml-2 btn-icon" color="secondary" type="border" icon="delete"
      @click="deleteAvatar()"></vs-button>
  </div>
	<div>
		<vs-input v-filter-input
			v-validate="'required'"
			data-vv-validate-on="blur"
			name="name"
			type="text"
			:label="$t('Name')"
			:placeholder="$t('Name')"
			class="w-full"
			v-model="profile.name"
		/>
		<span class="text-danger text-sm">{{ $t(errors.first('name')) }}</span>
	</div>
	<div>
		<vs-input v-filter-input
			v-validate="'required'"
			data-vv-validate-on="blur"
			name="company"
			type="text"
			:label="$t('Company')"
			:placeholder="$t('Company')"
			class="w-full"
			v-model="profile.company"
		/>
		<span class="text-danger text-sm">{{ $t(errors.first('company')) }}</span>
	</div>
	<div>
		<vs-input v-filter-input-email
			v-validate="'required|email'"
			data-vv-validate-on="blur"
			name="email"
			type="email"
			:label="$t('Email')"
			:placeholder="$t('Email')"
			class="w-full"
			v-model="profile.email"
		/>
		<span class="text-danger text-sm">{{ $t(errors.first('email')) }}</span>
	</div>
	<div>
		<vs-select
			name="language-settings-user"
			class="w-full"
			:label="$t('Language')"
			v-model="profile.language"
			>
			<vs-select-item
			v-for="item in languageOptions"
				:key="item.value"
				:value="item.value"
				:text="item.text"
			/>
		</vs-select>
	</div>
	<div>
		<vs-checkbox
			v-model="profile.agree"
      v-tooltip="{
        content: ModalTooltip(),
        loadingContent: 'Please wait...',
      }">
			{{ $t('I agree to use my profile details while using Koblatil') }}
		</vs-checkbox>
	</div>
  <modal
		class="modal-profie"
		size="medium"
		:hide-footer="true"
    :hasScroll="true"
		:Modal-close="true"
		:isEnableKeyUp="false"
		@close="closeModalCropper"
		v-if="isShowModalCropper">
		<div slot="header">
			<h5 class="modal-title">{{ $t('Set Profile Picture') }}</h5>
		</div>
		<div slot="body">
			<div>
        <ImageCropper :imageCropper="imageCropper" @cropImage="cropImage" @close="close"/>
			</div>
		</div>
	</modal>
</div>
</template>

<script>
import Modal from '@/components/Modal.vue';
import {
  getFirstCharacter, generateColorByAlphabet, getAxiosErrorMessage,
} from '@/lib/helper';
import ImageCropper from '@/components/ImageCropper.vue';
import fileApi from '@/api/file';

export default {
  components: {
    ImageCropper,
    Modal,
  },
  props: {
    profile: {
      type: Object,
      default: () => null,
    },
    isExtendedEvent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      languageOptions: [
        { value: 'no', text: 'Norsk' },
        { value: 'en', text: 'English' },
      ],
      isLocalProfile: false,
      localName: '',
      isShowModalCropper: false,
      isShowAvatar: true,
      imageCropper: null,
    };
  },
  watch: {

  },
  computed: {

  },
  methods: {
    ModalTooltip() {
      const headline = this.$t('Under the EU General Data Protection Regulation, we need your approval for our use of personal information (e.g. your name and email address) you may provide as we communicate:');
      const msg1 = this.$t('1. We\'ll store your personal information so that we can pick up the conversation if we talk later.');
      const msg2 = this.$t('2. We may send you emails to follow up on our discussion here.');
      const msg3 = this.$t('3. We may send you emails about upcoming events.');
      const final = `<div><p style="margin-bottom: 10px">${headline}</p><p>${msg1}</p><p>${msg2}</p><p>${msg3}</p></div>`;
      return final;
    },
    getInitial(str) {
      return getFirstCharacter(str);
    },
    getColor(str) {
      return generateColorByAlphabet(str);
    },
    // onFetchLocalProfile() {
    //   // this.$emit('useLocalProfile');
    //   const localProfile = JSON.parse(localStorage.getItem('userInfo'));
    //   const accessToken = localProfile.access_token;
    //   localStorage.setItem('access_token', accessToken);
    // },
    onGetFirstWord(str) {
      return str && str.length > 0 ? str[0] : '';
    },
    selectImage(input) {
      this.imageCropper = input;
      this.isShowModalCropper = true;
    },
    closeModalCropper() {
      this.imageCropper = null;
      this.$refs.updateImgProfile.value = null;
      this.isShowModalCropper = false;
    },
    close() {
      this.imageCropper = null;
      this.$refs.updateImgProfile.value = null;
      this.isShowModalCropper = false;
    },
    cropImage(image) {
      this.$vs.loading();
      this.imageCropper = null;
      this.$refs.updateImgProfile.value = null;
      this.isShowModalCropper = false;
      if (image) {
        this.isUploading = true;
        const file = image;
        const reader = new FileReader();
        reader.onload = (e) => {
          this.profile.avatar = e.target.result;
        };
        reader.readAsDataURL(file);

        // Upload Image
        const formData = new FormData();
        formData.append('file', file);

        const callback = (response) => {
          const url = response.data;
          this.profile.avatar = url;
          this.profile.avatar_thumbnail = url;
          this.isShowAvatar = false;
          setTimeout(() => {
            this.isShowAvatar = true;
          }, 500);
          this.isUploading = false;
          this.$vs.loading.close();
          this.imageCropper = null;
          this.$refs.updateImgProfile.value = null;
          this.isShowModalCropper = false;
        };

        const errorCallback = (e) => {
          const message = getAxiosErrorMessage(e);
          this.$vs.notify({
            title: this.$t('Upload Image'),
            text: message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          });
          this.isUploading = false;
          this.$vs.loading.close();
          this.imageCropper = null;
          this.$refs.updateImgProfile.value = null;
          this.isShowModalCropper = false;
        };
        fileApi.upload(formData, callback, errorCallback);
      }
    },
    deleteAvatar() {
      this.profile.avatar = '';
      this.profile.avatar_thumbnail = '';
      this.isShowAvatar = false;
      setTimeout(() => {
        this.isShowAvatar = true;
      }, 500);
      this.isUploading = false;
      this.imageCropper = null;
      this.$refs.updateImgProfile.value = null;
      this.isShowModalCropper = false;
    },
  },
  mounted() {
  },
  created() {

  },
  destroyed() {
  },
};
</script>

<style>
</style>
