import client from '../lib/http-client';
import { buildQuery } from '../lib/helper';

const endpoint = '/questions';


export default {
  // Get List
  list(eventId, params, cb, errorCb) {
    const url = `${endpoint}/${eventId}`;
    const responseHandler = (response) => {
      if (cb) cb(response.data);
    };
    const errorHandler = (e) => {
      if (errorCb) errorCb(e);
    };
    client.get(url, { params })
      .then(responseHandler)
      .catch(errorHandler);
  },

  // Create
  create(eventId, params, cb, errorCb) {
    const url = `${endpoint}/${eventId}`;
    const responseHandler = (response) => {
      if (cb) cb(response.data);
    };
    const errorHandler = (e) => {
      if (errorCb) errorCb(e);
    };
    client.post(url, params)
      .then(responseHandler)
      .catch(errorHandler);
  },

  // Get
  get(id, cb, errorCb) {
    const url = `${endpoint}/${id}`;
    const responseHandler = (response) => {
      if (cb) cb(response.data);
    };
    const errorHandler = (e) => {
      if (errorCb) errorCb(e);
    };
    client.get(url)
      .then(responseHandler)
      .catch(errorHandler);
  },

  // Update
  update(id, params, cb, errorCb) {
    const url = `${endpoint}/${id}`;
    client.put(url, params)
      .then((response) => {
        if (cb) cb(response.data);
      })
      .catch((e) => {
        if (errorCb) errorCb(e);
      });
  },

  publishPremade(id, cb, errorCb) {
    const url = `${endpoint}/${id}/premade`;
    client.put(url)
      .then((response) => {
        if (cb) cb(response.data);
      })
      .catch((e) => {
        if (errorCb) errorCb(e);
      });
  },

  // Delete
  delete(id, cb, errorCb) {
    const url = `${endpoint}/${id}`;
    client.delete(url)
      .then((response) => {
        if (cb) cb(response.data);
      })
      .catch((e) => {
        if (errorCb) errorCb(e);
      });
  },

  // Bulk Delete
  bulkDelete(ids, cb, errorCb) {
    const params = {
      ids: JSON.stringify(ids),
      // is_deleted: 1,
    };
    const url = `${endpoint}/bulk/delete`;
    const responseHandler = (response) => {
      if (cb) cb(response.data);
    };
    const errorHandler = (e) => {
      if (errorCb) errorCb(e);
    };
    client.delete(url, { data: params })
      .then(responseHandler)
      .catch(errorHandler);
  },

  // Starred
  starred(id, cb, errorCb) {
    const url = `${endpoint}/${id}/starred`;
    client.put(url)
      .then((response) => {
        if (cb) cb(response.data);
      })
      .catch((e) => {
        if (errorCb) errorCb(e);
      });
  },

  // Highlight
  highlight(id, cb, errorCb) {
    const url = `${endpoint}/${id}/highlight`;
    client.put(url)
      .then((response) => {
        if (cb) cb(response.data);
      })
      .catch((e) => {
        if (errorCb) errorCb(e);
      });
  },

  // Published
  published(id, cb, errorCb) {
    const url = `${endpoint}/${id}/published`;
    client.put(url)
      .then((response) => {
        if (cb) cb(response.data);
      })
      .catch((e) => {
        if (errorCb) errorCb(e);
      });
  },

  // archived
  archived(id, cb, errorCb) {
    const url = `${endpoint}/${id}/archived`;
    client.put(url)
      .then((response) => {
        if (cb) cb(response.data);
      })
      .catch((e) => {
        if (errorCb) errorCb(e);
      });
  },

  // Archived all
  archivedAll(eventId, cb, errorCb) {
    const url = `${endpoint}/${eventId}/archivedAll`;
    client.put(url)
      .then((response) => {
        if (cb) cb(response.data);
      })
      .catch((e) => {
        if (errorCb) errorCb(e);
      });
  },

  // publish all
  publishedAll(eventId, cb, errorCb) {
    const url = `${endpoint}/${eventId}/publishedAll`;
    client.put(url)
      .then((response) => {
        if (cb) cb(response.data);
      })
      .catch((e) => {
        if (errorCb) errorCb(e);
      });
  },

  // Like
  like(qid, cb, errorCb) {
    const url = `${endpoint}/likes/${qid}`;
    const responseHandler = (response) => {
      if (cb) cb(response.data);
    };
    const errorHandler = (e) => {
      if (errorCb) errorCb(e);
    };
    client.post(url)
      .then(responseHandler)
      .catch(errorHandler);
  },

  // Export Participants
  exportList(type, params, cb, errorCb) {
    const responseHandler = (response) => {
      if (cb) cb(response.data);
    };
    const errorHandler = (e) => {
      if (errorCb) errorCb(e);
    };
    const query = buildQuery(params);
    const url = `${endpoint}/export/${type}?${query}`;
    client.get(url)
      .then(responseHandler)
      .catch(errorHandler);
  },

  // Delete
  resetAll(eventId, cb, errorCb) {
    const url = `${endpoint}/${eventId}/reset`;
    client.delete(url)
      .then((response) => {
        if (cb) cb(response.data);
      })
      .catch((e) => {
        if (errorCb) errorCb(e);
      });
  },
};
