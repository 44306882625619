<template>
	<div class="active-announcement" v-if="isShow && isActive">
    <div :class="{ 'font-bold': isGlobal }">
      {{ announcement.title }}
    </div>

    <div v-if="isGlobal" class="">
      <div>
        {{ announcement.description }}
      </div>
    </div>
  </div>
</template>

<script>
import announcementApi from '@/api/announcement';

export default {
  components: {
  },
  props: {
    event: {
      type: Object,
      default: () => null,
    },
    isGlobal: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      isShow: true,
      announcement: null,
      isFetching: false,
    };
  },
  sockets: {
    announcements_add(announcement) {
      if (this.isParticipantRoute) {
        if (!this.announcement) {
          if (announcement.type !== 'user') {
            this.announcement = announcement;
          }
        }
      }
    },
    announcements_update(announcement) {
      if (this.isParticipantRoute) {
        if (announcement.type !== 'user') {
          this.announcement = announcement;
        }
      }
    },
    global_announcements_update(announcement) { // will only be called in Main.vue using global room socket
      if (announcement.type === 'user') {
        this.announcement = announcement;
      }
    },
  },
  computed: {
    eventId() {
      return this.event ? this.event.id : null;
    },
    isParticipantRoute() {
      return this.$route.name === 'EventLive';
    },
    isActive() {
      return this.announcement ? this.announcement.is_active : false;
    },
  },
  methods: {
    close() {
      this.isShow = false;
    },
    fetch() {
      if (!this.eventId) return;
      const type = 'participant';
      this.isFetching = true;
      const callback = (response) => {
        const announcement = response.data;
        this.announcement = announcement;
        this.isFetching = false;
      };
      const errorCallback = () => {
        this.isFetching = false;
      };
      announcementApi.getActive(this.eventId, type, callback, errorCallback);
    },
    fetchGlobal() {
      const type = 'user';
      this.isFetching = true;
      const callback = (response) => {
        const announcement = response.data;
        this.announcement = announcement;
        this.isFetching = false;
      };
      const errorCallback = () => {
        this.isFetching = false;
      };
      announcementApi.getActiveGlobal(type, callback, errorCallback);
    },
  },
  mounted() {
    if (this.isGlobal) {
      this.fetchGlobal();
    } else {
      this.fetch();
    }
  },
  created() {
  },
  destroyed() {
  },
};
</script>
