<template>
	<modal
		class="modal-profie"
		size="small-medium"
		:hide-footer="true"
    :isMobile="windowWidth < 700 ? true : false"
    :hasScroll="true"
		:show-close="true"
		:isEnableKeyUp="false"
		@close="close"
		v-if="show">
		<div slot="header">
			<h5 class="modal-title">{{ $t('My Profile') }}</h5>
		</div>
		<div slot="body">
			<div>
				<profile-edit-form :isExtendedEvent="isExtendedEvent" :profile="profile" v-if="isEdit && !isShowQr" />
				<profile-details :isExtendedEvent="isExtendedEvent" :profile="profile" v-if="!isEdit && !isShowQr" />
				<profile-q-r :profile="profile" v-if="isShowQr" />

				<!-- Actions -->
				<div
          :class="{'justify-end': profile.name === 'Anonymous', 'justify-between': profile.name !== 'Anonymous', }"
          class="flex mt-10">
					<div v-if="profile.name !== 'Anonymous'">
						<span class="text-blue cursor-pointer" @click="showDelete()" v-if="!isEdit">
							{{ $t("Delete My Profile") }}
						</span>
					</div>
					<div class="">
						<div class="flex space-x-4" v-if="isEdit">
							<vs-button
								:class="{ 'is-loading': isSaving , }"
								:disabled="isSaving "
								@click="closeEdit()"
								color="#797979"
								type="filled"
								class="submit-button"
								>
								{{ $t('Cancel') }}
							</vs-button>
							<vs-button
								:class="{ 'is-loading': isSaving }"
								:disabled="isSaving || (profile && !profile.agree)"
								@click="updateProfile()"
								color="primary"
								type="filled"
								class="submit-button"
								>
								{{ $t('Save') }}
							</vs-button>
              <vs-button
                v-if="isExtendedEvent"
                @click="showQr()"
                color="secondary"
                type="filled"
                class="submit-button ml-2 text-black"
                >
                {{ !isShowQr ? $t('Show QR Code') : $t('Hide QR Code') }}
              </vs-button>
						</div>
						<div class="flex space-x-4" v-if="!isEdit">
              <vs-button
                @click="showEdit()"
                color="primary"
                type="filled"
                class="submit-button"
                >
                {{ $t('Edit') }}
              </vs-button>
              <vs-button
                v-if="isExtendedEvent"
                @click="showQr()"
                color="secondary"
                type="filled"
                class="submit-button ml-2 text-black"
                >
                {{ !isShowQr ? $t('Show QR Code') : $t('Hide QR Code') }}
              </vs-button>
            </div>
					</div>
				</div>
			</div>
		</div>
	</modal>
</template>

<script>

import { mapGetters } from 'vuex';
import Modal from '@/components/Modal.vue';
import ProfileDetails from '@/components/events/profile/ProfileDetails.vue';
import ProfileEditForm from '@/components/events/profile/ProfileEditForm.vue';
import ProfileQR from '@/components/events/profile/ProfileQR.vue';
import participantApi from '@/api/participant';
// import { duplicateVar } from '@/lib/helper';

export default {
  components: {
    Modal,
    ProfileDetails,
    ProfileEditForm,
    ProfileQR,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    event: {
      type: Object,
      default: null,
    },
    participant: {
      type: Object,
      default: null,
    },
    isExtendedEvent: {
      type: Boolean,
      default: false,
    },

  },
  data() {
    return {
      accessCode: null,
      isSubmitting: false,
      isEdit: false,
      isShowQr: false,
      isSaving: false,
      profile: {
        name: '',
        company: '',
        email: '',
        language: 'no',
        agree: false,
        avatar: '',
        avatar_thumbnail: '',
      },
      windowWidth: window.innerWidth,
    };
  },
  mounted() {
    this.setProfile();
    this.checkScreenSize();
  },
  created() {

  },
  destroyed() {
  },
  watch: {
    show() {
      if (!this.show) this.closeEdit();
      if (this.profile.name === 'Anonymous') this.isEdit = true;
    },
    user() {
      if (this.user) this.setProfile();
    },
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'isLoggedIn',
      user: 'user',
    }),
  },
  methods: {
    checkScreenSize() {
      window.onresize = () => {
        this.windowWidth = window.innerWidth;
      };
    },
    close() {
      this.$emit('close');
    },
    setProfile() {
      const userProfile = this.user;
      this.profile = userProfile;
    },
    onDeleteProfile(profile) {
      this.profile = profile;
    },
    updateProfile() {
      const callback = (response) => {
        const item = response.data;
        this.profile.name = item.name;
        this.profile.email = item.email;
        this.profile.language = item.language;
        this.profile.company = item.company;
        this.profile.agree = item.agree;
        this.profile.avatar = item.avatar;
        this.profile.avatar_thumbnail = item.avatar_thumbnail;
        this.profile.access_token = localStorage.getItem('access_token');
        localStorage.setItem('userInfo', JSON.stringify(this.profile));
        this.$store.dispatch('updateUserInfo', this.profile);
        this.$store.dispatch('setUser', item);
      };
      const errCallback = () => {
      };
      const params = {
        name: this.profile.name,
        email: this.profile.email,
        company: this.profile.company,
        language: this.profile.language,
        avatar: this.profile.avatar,
        avatar_thumbnail: this.profile.avatar_thumbnail,
      };
      participantApi.saveProfile(this.event.id, params, callback, errCallback);

      this.closeEdit();
    },
    showEdit() {
      this.isEdit = true;
      this.isShowQr = false;
    },
    closeEdit() {
      this.isEdit = false;
    },
    showQr() {
      this.isShowQr = !this.isShowQr;
      this.isEdit = false;
    },
    showDelete() {
      this.$emit('showDeleteProfile');
    },
  },
};
</script>
