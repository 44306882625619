<template>
	<div class="sender-user-selector">
			<div
				class="flex items-center">
        <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
          <vs-avatar :src="selectedUser && selectedUser.picture ? selectedUser.picture : ''"  v-if="selectedUser && selectedUser.picture" />
          <vs-avatar v-else :color="getColor(selectedUser && selectedUser.name)" :text="getInitial(selectedUser && selectedUser.name)" />
          <vs-dropdown-menu class="sender-user-dropdown" v-if="userOptions.length > 1">
              <vs-dropdown-item
                v-for="(option, index) in userOptions"
                :key="index"
                @click="setUser(option)"
                >
                <div class="flex cursor-pointer items-center">
                  <vs-avatar size="25px" :src="option.picture ? option.picture : ''"  v-if="option.picture" />
                  <vs-avatar size="25px" :color="getColor(option && option.name)" :text="getInitial(option && option.name)" v-else />
                  <span
                    class="ml-2"
                    :class="`${selectedUser && selectedUser.id == option.id ? 'font-bold' : ''}`"
                    >
                    <span>{{ selectedUser && selectedUser.id !== option.id ? $t("Switch to") : $t("Stay as") }} </span>
                    {{ option.name && option.id !== 0 ? isMobileScreen && option.name && option.name.length > 12 ? `${option.name.substring(0, 12)}...` : option.name : $t("Anonymous") }}
                  </span>
                </div>
              </vs-dropdown-item>
            </vs-dropdown-menu>
        </vs-dropdown>
				<span class="flex items-center">

          <vs-input v-filter-input
            v-if="selectedUser && !isReloading"
            type="text"
            name="text"
            :placeholder="$t('Your name (optional)')"
            v-model="selectedUser.name"
            class="w-full input-user-name-selector"
            :class="`${selectedUser && selectedUser.id !== 0 ? 'cursor-pointer' : ''}`"
            :readonly="(selectedUser && selectedUser.id !== 0) || userOptions.length > 1"
            @focusout="onInputUsername()"
          />
				</span>
			</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  getFirstCharacter,
  generateColorByAlphabet,
} from '@/lib/helper';
import participantApi from '@/api/participant';

// const USERNAME_SENDER_KEY_NAME = 'username_sender_key_name';

export default {
  components: {
  },
  props: {
    event: {
      type: Object,
      default: () => null,
    },
    isShowVotingAsLabel: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      selectedUser: null,
      inputUsername: '',
      profile: {
        name: '',
        company: '',
        email: '',
        language: 'en',
        agree: false,
      },
      isReloading: false,
      windowWidth: window.innerWidth,
    };
  },
  mounted() {
    this.setInitial();
    this.checkScreenSize();
  },
  created() {
  },
  destroyed() {
  },
  watch: {
    isProfileUpdated() {
      this.isReloading = true;
      setTimeout(() => {
        this.setInitial();
        this.isReloading = false;
      }, 5);
    },
    user() {
      if (this.user) this.setInitial();
    },
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'isLoggedIn',
      user: 'user',
    }),
    userId() {
      const name = this.user && this.user.name ? `${this.user.id}` : 1;
      return name;
    },
    userName() {
      const name = this.user && this.user.name ? `${this.user.name}` : '';
      return name;
    },
    userAvatar() {
      const avatar = this.user && this.user.avatar_thumbnail ? `${this.user.avatar_thumbnail}` : '';
      return avatar;
    },
    isProfileUpdated() {
      const labelAnonymous = 'Anonymous';
      const isProfileUpdated = this.user && this.user.name && this.user.name !== labelAnonymous;
      return isProfileUpdated;
    },
    totalUserOptions() {
      return this.userOptions.length;
    },
    userOptions() {
      let firstOptionName = '';

      if (this.isProfileUpdated) firstOptionName = 'Anonymous';
      const options = [
        { id: 0, name: firstOptionName, picture: null },
      ];

      if (this.isProfileUpdated) {
        const userData = { id: this.userId, name: this.userName, picture: this.userAvatar };
        options.push(userData);
      }

      return options;
    },
    isMobileScreen() {
      return this.windowWidth < 500;
    },
  },
  methods: {
    checkScreenSize() {
      window.onresize = () => {
        this.windowWidth = window.innerWidth;
      };
    },
    generateInitial(text = '') {
      const words = text || 'AN';
      const initial = words.length > 1 ? `${words[0][0]}${words[1][0]}` : words[0][0];
      return initial;
    },
    setInitial() {
      let selected = null;
      if (!this.selectedUser && this.userOptions.length > 1) {
        selected = this.userOptions[1];
      } else if (!this.selectedUser || (this.selectedUser && this.selectedUser.name === '')) {
        selected = this.userOptions[0];
      } else if (this.selectedUser && this.userOptions.length > 1) {
        selected = this.userOptions[1];
      }

      this.selectedUser = selected;
      this.setSelectedUser(this.selectedUser);
    },
    setUser(user) {
      this.selectedUser = user;
      this.setSelectedUser(user);
    },
    setSelectedUser(user) {
      this.$emit('setSelectedUser', user);
    },
    getInitial(str) {
      return getFirstCharacter(str);
    },
    getColor(str) {
      return generateColorByAlphabet(str);
    },
    onInputUsername() {
      if (this.userOptions.length > 1) return;

      if (this.selectedUser && this.selectedUser.name) {
        const newName = this.selectedUser.name;
        this.onUpdateUserName(newName);
      }
    },
    onUpdateUserName(newName = '') {
      if (!newName) return;

      const params = {
        name: newName,
      };

      const callback = (response) => {
        const item = response.data;
        this.profile.name = item.name;
        this.profile.email = item.email;
        this.profile.language = item.language;
        this.profile.company = item.company;
        this.profile.agree = item.agree;
        this.profile.access_token = localStorage.getItem('access_token');
        localStorage.setItem('userInfo', JSON.stringify(this.profile));
        this.$store.dispatch('updateUserInfo', this.profile);
        this.$store.dispatch('setUser', item);
      };
      const errCallback = () => {
      };
      participantApi.saveProfile(this.event.id, params, callback, errCallback);
    },
  },
};
</script>
