<template>
	<div>
		<div class="question-item">
			<!-- Question -->
			<div class="question-wrapper">
				<div class="flex justify-between">
					<div class="flex space-x-2">
						<div>
							<div class="thumbnail-profile-dropdown-main">
                <vs-avatar v-if="question && question.participant && question.participant.avatar_thumbnail" class="p-0 m-0" :src="question.participant.avatar_thumbnail" size="40px" />
                <vs-avatar
                v-if="question && question.participant && !question.participant.avatar_thumbnail"
                class="p-0 m-0"
                :color="getColor(question.sender_name)" :text="getInitial(question.sender_name)"
								size="40px"
                />

							</div>
						</div>
						<div>
							<div class="font-bold">{{ question.sender_name === 'Anonymous' ? $t('Anonymous') : question.sender_name }}</div>
							<div class="question-item__created-time">{{ getCreatedQuestionTime(question.created_at) }}</div>
						</div>
					</div>
					<div class="flex space-x-2 items-start">
            <!-- Moderated Icon -->
						<div
              v-tooltip="$t('This question is being moderated and only visible for you')"
              class="flex items-center space-x-1"
              v-if="isQuestionModerated && !question.is_published"
              >
              <vs-icon
                class="question-item__moderated-icon cursor-pointer"
                size="small"
                >
                verified_user
              </vs-icon>
						</div>

						<!-- Total Reply -->
						<div
              v-if="isShowTotalReply"
              class="flex items-center space-x-1"
              @click="viewDetails(question)"
              id="total-view-reply-icon">
							<feather-icon class="cursor-pointer" svgClasses='h-4 w-4' icon="CornerDownRightIcon" />
							<div class="question-item__total-like">{{ totalReply }}</div>
						</div>

						<!-- Total Like -->
						<div
              class="flex items-center space-x-1"
              v-if="isShowTotalLike">
							<div class="question-item__total-like" v-if="!isLiking">{{ question.likes }}</div>
              <vs-icon
                class="question-item__like-icon cursor-pointer"
                size="small"
                :class="{ 'is-loading dark': isLiking, 'is-liked': isLiked }"
                @click="likeToggle()"
                >
                thumb_up
              </vs-icon>
						</div>

						<!-- Action -->
						<div class="flex items-center space-x-1" v-if="isShowAction">
							<vs-dropdown @click="isEditingAllowed(question)" vs-custom-content vs-trigger-click class="cursor-pointer" >
								<div>
									<feather-icon class="question-item__like-action" svgClasses='h-4 w-4' icon="MoreVerticalIcon" />
								</div>
								<vs-dropdown-menu>
                  <vs-dropdown-item
                    v-if="checkReplies(question)"
                    @click="showReply(question)">
										{{ $t("Reply") }}
									</vs-dropdown-item>
                  <vs-dropdown-item
                    v-if="isShowActionDetails"
										@click="viewDetails(question)">
										{{ $t("Details") }}
									</vs-dropdown-item>
									<vs-dropdown-item
                    @click="showEdit(question)"
                    v-if="isMyQuestion(question) && isEditingAllowed(question)">
										{{ $t("Edit") }}
									</vs-dropdown-item>
									<vs-dropdown-item
                    v-if="isMyQuestion(question) && isQuestionWithdrawalEnabled"
										divider
										@click="showWithdrawQuestion(question)">
										<div class="text-danger">
											{{ $t("Withdraw") }}
										</div>
									</vs-dropdown-item>
								</vs-dropdown-menu>
							</vs-dropdown>
						</div>
					</div>
				</div>
				<div class="mt-2">
					{{ question.name }}
				</div>
			</div>

			<!-- First Reply -->
			<div class="answer-wrapper" v-if="isShowFirstReply && firstReply && isQuestionVisible(question, firstReply)">
				<div class="flex">
					<div class="mr-1">
						<feather-icon
              class="answer-right-icon"
              svgClasses='h-5 w-5'
              icon="CornerDownRightIcon"
            />
					</div>
					<div>
						<div class="flex space-x-2 items-center">
							<vs-icon class="answer-question-icon" v-if="firstReply.sender_name === 'Moderator'" color="#19A50F">check_circle</vs-icon>
              <div v-else>
                <vs-avatar
                  v-if="!firstReply.participant.avatar_thumbnail"
                  class="p-0 m-0"
                  :color="getColor(firstReply.sender_name)" :text="getInitial(firstReply.sender_name)"
                  size="28px"
                />
                <vs-avatar
                  v-if="firstReply.participant.avatar_thumbnail"
                  class="p-0 m-0"
                  :src="question.participant.avatar_thumbnail"
                  size="28px"
                />
              </div>
							<div>
								<div class="font-bold">{{ firstReply.sender_name }}</div>
								<div class="question-item__created-time">{{ getCreatedQuestionTime(firstReply.created_at) }}</div>
							</div>
						</div>
						<div class="mt-2">
							{{ firstReply.name }}
						</div>
					</div>
				</div>
			</div>

			<!-- List Replies -->
			<div class="answer-wrapper" v-if="isShowListReplies && question.replies.length > 0">
				<div class="font-bold">{{ totalReply }} {{ $t("Replies") }}</div>
				<div class="ml-6 mt-4 space-y-4">
					<div v-for="(reply, index) in question.replies" :key="index">
            <div v-if="isQuestionVisible(question, reply)">
              <div class="flex space-x-2 items-center" >
                <vs-icon class="answer-question-icon" v-if="reply.sender_name === 'Moderator'" color="#19A50F">check_circle</vs-icon>
                <div v-else>
                <vs-avatar
                  v-if="!reply.participant.avatar_thumbnail"
                  class="p-0 m-0"
                  :color="getColor(reply.sender_name)" :text="getInitial(reply.sender_name)"
                  size="28px"
                />
                <vs-avatar
                  v-if="reply.participant.avatar_thumbnail"
                  class="p-0 m-0"
                  :src="reply.participant.avatar_thumbnail"
                  size="28px"
                />
              </div>
                <div>
                  <div class="font-bold">{{ reply.sender_name }}</div>
                  <div class="question-item__created-time">{{ getCreatedQuestionTime(reply.created_at) }}</div>
                </div>
              </div>
              <div class="mt-2">
                {{ reply.name }}
              </div>
            </div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import dayjs from 'dayjs';
// import enLocale from '@/lib/locale/en';
import noLocale from '@/lib/locale/no';
import relativeTime from 'dayjs/plugin/relativeTime';

import { mapGetters } from 'vuex';
import questionApi from '@/api/question';
import { getFirstCharacter, generateColorByAlphabet } from '@/lib/helper';

dayjs.locale('en');
dayjs.extend(relativeTime);

export default {
  components: {
  },
  props: {
    question: {
      type: Object,
      default: () => null,
    },
    isShowFirstReply: {
      type: Boolean,
      default: () => false,
    },
    isShowTotalReply: {
      type: Boolean,
      default: () => false,
    },
    isShowTotalLike: {
      type: Boolean,
      default: () => false,
    },
    isShowAction: {
      type: Boolean,
      default: () => true,
    },
    isShowListReplies: {
      type: Boolean,
      default: () => false,
    },
    isShowActionDetails: {
      type: Boolean,
      default: () => true,
    },
    eventSettings: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      isLiking: false,
    };
  },
  mounted() {
  },
  created() {
  },
  destroyed() {
  },
  computed: {
    ...mapGetters({
      user: 'user',
    }),
    userLang() {
      const lang = this.user && this.user.language ? this.user.language : 'en';
      return lang;
    },
    replies() {
      return this.question && this.question.replies ? this.question.replies : [];
    },
    firstReply() {
      const firstReply = this.replies && this.replies.length > 0 ? this.replies[0] : null;
      if (this.isMyQuestion(this.question) && firstReply && firstReply.is_private) return firstReply;

      const publicReplies = this.replies.filter(curr => !curr.is_private);
      const publicReply = publicReplies && publicReplies.length > 0 ? publicReplies[0] : null;
      const reply = publicReply || firstReply;
      return reply;
    },
    totalReply() {
      return this.replies.length;
    },
    isLiked() {
      return this.question && this.question.meta && this.question.meta.is_participant_like;
    },
    isQuestionModerated() {
      let isModerated = false;
      const qnaSettings = this.eventSettings && this.eventSettings.question_answer ? this.eventSettings.question_answer : null;
      if (qnaSettings && qnaSettings.is_moderated) isModerated = true;
      return isModerated;
    },
    isQuestionWithdrawalEnabled() {
      let isEnabled = false;
      const qnaSettings = this.eventSettings && this.eventSettings.question_answer ? this.eventSettings.question_answer : null;
      if (qnaSettings && qnaSettings.is_withdrawal) isEnabled = true;
      return isEnabled;
    },
  },
  methods: {
    generateInitial() {
      const words = this.question.sender_name ? this.question.sender_name.split(' ') : 'AN';
      const initial = `${words[0][0]}` || 'A';
      return initial;
    },
    likeToggle() {
      if (this.isLiking) return;

      this.isLiking = true;
      const callback = (response) => {
        this.isLiking = false;
        const item = response.data;
        this.$emit('onLike', item);
      };
      const errorCallback = () => {
        this.isLiking = false;
      };
      questionApi.like(this.question.id, callback, errorCallback);
    },
    isPrivate(question) {
      return question && !!question.is_private;
    },
    isMyQuestion(question) {
      const isValid = this.user && question && parseInt(question.participant_id) === parseInt(this.user.id);
      return isValid;
    },
    checkReplies(question) {
      const isValid = this.user && question && parseInt(question.participant_id) !== parseInt(this.user.id);
      const isRepliesEnabled = this.eventSettings && this.eventSettings.question_answer && this.eventSettings.question_answer.is_replies ? this.eventSettings.question_answer.is_replies : false;
      return isValid && isRepliesEnabled;
    },
    isQuestionVisible(parentQuestion, question) {
      let isVisible = true;
      if (this.isMyQuestion(parentQuestion)) {
        if (this.isPrivate(question)) isVisible = true;
      } else if (!this.isMyQuestion(parentQuestion)) {
        if (!this.isPrivate(question)) isVisible = true;
        else isVisible = false;
      } else {
        isVisible = false;
      }
      return isVisible;
    },
    viewDetails(question) {
      this.$emit('viewSelected', question);
    },
    showWithdrawQuestion(question) {
      this.$emit('showWithdrawQuestion', question);
    },
    getCreatedQuestionTime(dateTime) {
      if (this.userLang === 'no') dayjs.locale(noLocale);
      else dayjs.locale('en');

      return dayjs(dateTime || new Date()).fromNow();
    },
    showEdit(question) {
      if (this.isEditingAllowed(question)) this.$emit('showEdit', question);
      else this.$emit('showWarningEdit', question);
    },
    showReply(question) {
      this.$emit('showReply', question);
    },
    isEditingAllowed(question) {
      const createdAt = question ? new Date(question.created_at) : false;
      if (!createdAt) return false;

      // Calculate the time difference in milliseconds
      const currentTime = new Date();
      const timeDifference = currentTime - createdAt;

      // Convert the time difference to minutes
      const timeDifferenceInMinutes = timeDifference / (1000 * 60);

      // Check if the time difference is within the 5-minute limit
      return timeDifferenceInMinutes <= 5;
    },
    getInitial(str) {
      return getFirstCharacter(str);
    },
    getColor(str) {
      return generateColorByAlphabet(str);
    },
  },
};
</script>
