<template>
	<div
		class="input-question-wrapper"
		:class="`${isShow ? 'focused': ''} ${isHideBorder ? 'hide-border' : ''}`"
		@click="onFocusInputQuestion()"
		@focusout="onBlurInputQuestion()"
		@mouseover="onMouseOverInputQuestion()"
		@mouseleave="onMouseLeaveInputQuestion()"
		tabindex="0"
    @submit.prevent="submit"
		>
		<div
      class="flex"
      :class="`${isShow ? '' : 'h-full'}`">
			<vs-icon class="input-add-question-icon" color="#C6C6C6" v-if="!isShow">account_circle</vs-icon>
			<vs-textarea
        :style="eventFont"
				icon="account_circle"
				:placeholder="$t('Type in your question')"
				v-model="question.name"
				class="w-full input-question-block"
        @input="onInput"
				>
			</vs-textarea>
		</div>

    <div class="input-question-limit-info mr-3" v-if="isShow">
      {{ questionName.length }}/{{ questionInputLength }}
    </div>

		<!-- Actions -->
		<div
      class="flex justify-between mt-4 action-add-question"
      :class="`${isShow ? '' : 'hidden'}`">
			<div>
        <user-selector
          :is-show-voting-as-label="false"
          :event="event"
          @setSelectedUser="setSelectedUser"
          v-if="!isEdit"
          >
        </user-selector>
      </div>
			<div class="flex items-center">
				<button
          class="button-primary-mobile"
					:class="{ 'is-loading': isSaving, 'button-primary-mobile-hover': !isFormValid || isSaving }"
					@click="submit()"
					>
          <div class="flex items-center">
            {{ $t('Send') }}
            <svg class="w-6 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="currentColor" d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z" /></svg>
          </div>
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import questionApi from '@/api/question';
import UserSelector from '@/components/sender/UserSelector.vue';
import { duplicateVar } from '@/lib/helper';

const QUESTION_DEFAULT = {
  sender_name: 'Anonymous',
  name: '',
};

export default {
  components: {
    UserSelector,
  },
  props: {
    isCollapseable: {
      type: Boolean,
      default: () => false,
    },
    isHideBorder: {
      type: Boolean,
      default: () => false,
    },
    event: {
      type: Object,
      default: () => null,
    },
    selected: {
      type: Object,
      default: () => null,
    },
    parentQuestion: {
      type: Object,
      default: () => null,
    },
    eventSettings: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      isInputFocused: false,
      isInputHovered: false,
      isSaving: false,
      question: duplicateVar(QUESTION_DEFAULT),
      selectedUser: null,
    };
  },
  sockets: {
    votings_active(voting) {
      if (voting && !this.question.name) {
        this.$emit('setFilterTab', 'polls');
      }
    },
  },
  mounted() {
    this.setData();
  },
  created() {
  },
  destroyed() {
  },
  watch: {
    selected() {
      this.setData();
    },
    questionName() {
      let newValue = this.question.name;

      // Limit character
      if (newValue.length > this.questionInputLength) {
        newValue = newValue.substring(0, this.questionInputLength);
      }

      this.question.name = newValue;
    },
    isShow() {
      this.$emit('isQuestionCollapse', this.isShow);
    },
  },
  computed: {
    isShow() {
      const isValid = (this.isInputFocused || this.isSaving) || !this.isCollapseable;
      return isValid;
    },
    isFormValid() {
      return this.question.name;
    },
    isEdit() {
      return this.selected && this.selected.id;
    },
    questionName() {
      return this.question.name;
    },
    questionInputLength() {
      let inputLength = 160;
      const qnaSettings = this.eventSettings && this.eventSettings.question_answer ? this.eventSettings.question_answer : null;
      if (qnaSettings) inputLength = qnaSettings.input_max_length;
      return inputLength;
    },
    eventFont() {
      const font = this.eventSettings && this.eventSettings.style && this.eventSettings.style.display_font ? this.eventSettings.style.display_font : 'Roboto';
      return `font-family: ${font}`;
    },
  },
  methods: {
    onFocusInputQuestion() {
      this.isInputFocused = true;
    },
    onBlurInputQuestion() {
      if (!this.isInputHovered && !this.isFormValid) {
        this.resetForm();
        this.isInputFocused = false;
      }
    },
    forceCloseForm() {
      this.isInputFocused = false;
    },
    onMouseOverInputQuestion() {
      this.isInputHovered = true;
    },
    onMouseLeaveInputQuestion() {
      this.isInputHovered = false;
    },
    submit() {
      if (!this.questionName) return;

      if (this.isEdit) this.onUpdate();
      else this.onCreate();
    },
    onCreate() {
      // Default
      const params = {
        sender_name: this.selectedUser && this.selectedUser.name ? this.selectedUser.name : 'Anonymous',
        name: this.question.name,
      };

      // Parent
      if (this.parentQuestion) params.parent_id = this.parentQuestion.id;

      this.isSaving = true;
      this.isFetching = true;
      const callback = () => {
        this.isSaving = false;
        this.onSuccess();
        this.forceCloseForm();

        this.$vs.notify({
          text: this.$t('Question successfully sent'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success',
        });
      };
      const errorCallback = (error) => {
        this.isSaving = false;

        // Wait 1 minute warning
        if (error.response && error.response.status === 429) {
          const message = this.$t('Please wait for a minute before replying another question');
          this.$vs.notify({
            title: this.$t('Question'),
            text: message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning',
          });
        }
      };
      questionApi.create(this.event.id, params, callback, errorCallback);
    },
    onUpdate() {
      // Default
      const params = {
        name: this.question.name,
      };

      this.isSaving = true;
      this.isFetching = true;
      const callback = () => {
        this.isSaving = false;
        this.onSuccess();
        this.forceCloseForm();

        this.$vs.notify({
          title: this.$t('Question'),
          text: this.$t('Question updated'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success',
        });
      };
      const errorCallback = () => {
        this.isSaving = false;
      };
      questionApi.update(this.selected.id, params, callback, errorCallback);
    },
    onSuccess() {
      this.resetForm();
      this.onBlurInputQuestion();
      this.closeModal();
    },
    resetForm() {
      this.question.name = '';
    },
    closeModal() {
      this.question = duplicateVar(QUESTION_DEFAULT);
      this.$emit('close');
    },
    setSelectedUser(user) {
      this.selectedUser = user;
    },
    onInput() {
      this.onFocusInputQuestion();
    },
    setData() {
      if (this.selected) this.question = this.selected;
    },
    sanitizeInput(input) {
      // Regular expression to match URLs
      const urlRegex = /(https?:\/\/[^\s]+)/g;

      // Replace URLs with an empty string
      const sanitizedInput = input.replace(urlRegex, '');

      return sanitizedInput;
    },
  },
};
</script>
